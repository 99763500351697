import { Suspense, useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Await, useLoaderData } from "@remix-run/react";

import CurveArrowIcon from "icons/curve-arrow.svg?react";

import { SearchSelect, serviceOptions } from "basics/select";

import { APP_URL } from "constants/domains";

import { Loader } from "../../../../duck/types";
import { DEFAULT_CRYPTOCURRENCIES } from "../../../../duck/constants";

import classes from "./styles/classes.module.scss";

import SubmitButton from "./components/submit-button";

const BuyForm = () => {
  const { currencies, cryptocurrencies } = useLoaderData<Loader>();

  const form = useForm({
    defaultValues: { cryptocurrency: null, currency: null },
  });

  const cryptocurrency = form.watch("cryptocurrency");
  const currency = form.watch("currency");

  const joinedCurrencies = useMemo(
    () => Promise.all([currencies, cryptocurrencies]),
    [],
  );

  return (
    <FormProvider {...form}>
      <form
        className={classes.wrapper}
        onSubmit={event => {
          event.preventDefault();
          window.open(
            `${APP_URL}/buy/${cryptocurrency.letterId}?themeCurrencyId=${currency.id}`,
            "_blank",
          );
        }}
      >
        <div className={classes.countriesLabel}>
          <p className={classes.text}>150 countries available</p>
          <CurveArrowIcon className={classes.arrowIcon} />
        </div>
        <Suspense
          fallback={
            <>
              <SearchSelect
                className={classes.currencySelect}
                classNames={{ button: classes.selectButton }}
                isLoading
                size="md"
                buttonLabel="You give:"
              />
              <SearchSelect
                className={classes.cryptocurrencySelect}
                classNames={{ button: classes.selectButton }}
                isLoading
                size="md"
                buttonLabel="You get:"
              />
            </>
          }
        >
          <Await resolve={joinedCurrencies}>
            {([currencies, cryptocurrencies]) => {
              // eslint-disable-next-line
              useEffect(() => {
                form.setValue(
                  "currency",
                  currencies.find(currency => currency.default),
                );
                form.setValue(
                  "cryptocurrency",
                  DEFAULT_CRYPTOCURRENCIES.Bitcoin,
                );
              }, []);

              return (
                <>
                  <SearchSelect
                    className={classes.currencySelect}
                    classNames={{
                      button: classes.selectButton,
                      input: classes.selectButton,
                    }}
                    isLoading={!currency}
                    size="md"
                    placeholder="Search for currency"
                    value={currency}
                    onChange={currency => form.setValue("currency", currency)}
                    options={currencies}
                    buttonLabel="You give:"
                    renderButton={serviceOptions.Button}
                    renderOption={serviceOptions.Option}
                    onFilter={(query, { value }) =>
                      value.name.toLowerCase().includes(query)
                    }
                  />
                  <SearchSelect
                    className={classes.cryptocurrencySelect}
                    classNames={{
                      button: classes.selectButton,
                      input: classes.selectButton,
                    }}
                    isLoading={!cryptocurrency}
                    size="md"
                    placeholder="Search for cryptocurrency"
                    value={cryptocurrency}
                    onChange={cryptocurrency =>
                      form.setValue("cryptocurrency", cryptocurrency)
                    }
                    options={cryptocurrencies}
                    buttonLabel="You get:"
                    renderButton={props => (
                      <serviceOptions.Button iconType="coin" {...props} />
                    )}
                    renderOption={props => (
                      <serviceOptions.Option iconType="coin" {...props} />
                    )}
                    onFilter={(query, { value }) =>
                      value.name.toLowerCase().includes(query)
                    }
                  />
                </>
              );
            }}
          </Await>
        </Suspense>
        <SubmitButton currency={currency} cryptocurrency={cryptocurrency} />
      </form>
    </FormProvider>
  );
};

export default BuyForm;
