import joinClassNames from "classnames";

import RoutingIcon from "icons/routing.svg?react";

import globalClasses from "styles/classes.module.scss";

// import LearnLink from "components/learn-link/LearnLink";

import mainClasses from "../../styles/classes.module.scss";
import classes from "./styles/classes.module.scss";

const IntelligentBuying = () => (
  <section className={joinClassNames(mainClasses.section, classes.section)}>
    <div className={classes.contentWrapper}>
      <div className={classes.iconWrapper}>
        <RoutingIcon className={classes.routingIcon} />
      </div>
      <div className={classes.descriptionWrapper}>
        <h2
          className={joinClassNames(globalClasses.gradientTitle, classes.title)}
        >
          Intelligent Crypto Buying Companion
        </h2>
        <p className={classes.description}>
          Our engine carefully evaluates your needs and optimizes your on-ramp
          experience. So you can spend more time on the things you care about,
          and worry less on the ideal provider while you HODL
        </p>
        {/*<LearnLink>Learn more</LearnLink>*/}
      </div>
    </div>
  </section>
);

export default IntelligentBuying;
