import React, { FC, Suspense, useMemo } from "react";
import joinClassNames from "classnames";
import queryString from "query-string";
import { Await, useLoaderData } from "@remix-run/react";

import { DefaultCoinNames } from "constants/coins";
import { APP_URL } from "constants/domains";

import { Loader } from "../../../../duck/types";

import Price from "./components/price";
import InsightInfo from "./components/insight-info";
import Chart from "./components/chart";

import classes from "./styles/classes.module.scss";

interface Props {
  coinName: DefaultCoinNames;
  code: Coin["symbol"];
}

const InsightCard: FC<Props> = ({ coinName, code }) => {
  const { insights, quotes } = useLoaderData<Loader>();

  const joinedInsight = useMemo(() => Promise.all([insights, quotes]), []);

  const content = (
    <>
      <p className={classes.coinName}>{coinName}</p>
      <h2 className={classes.title}>Buy {code}</h2>
      <div className={classes.chartHeaderWrapper}>
        <Price coinName={coinName} />
        <p className={classes.range}>7D</p>
      </div>
      <Chart coinName={coinName} />
      <InsightInfo coinName={coinName} />
    </>
  );

  const defaultInsight = (
    <div
      data-theme={coinName}
      className={joinClassNames(classes.themePresets, classes.wrapper)}
    >
      {content}
    </div>
  );

  return (
    <Suspense fallback={defaultInsight}>
      <Await resolve={joinedInsight}>
        {([insights]) => {
          const { cryptocurrency, id, network, isDefault, region } =
            insights[coinName];

          if (isDefault) {
            return defaultInsight;
          }

          return (
            <a
              data-theme={coinName}
              className={joinClassNames(
                classes.themePresets,
                classes.wrapper,
                classes.link,
              )}
              target="_blank"
              href={`${APP_URL}/buy/${cryptocurrency.letterId}/${
                region.letterId
              }/${id}?${queryString.stringify({
                amount: 100,
                networkId: network.id,
              })}`}
            >
              {content}
            </a>
          );
        }}
      </Await>
    </Suspense>
  );
};

export default InsightCard;
