import joinClassNames from "classnames";

import globalClasses from "styles/classes.module.scss";

import Button from "basics/button";

import { APP_URL } from "constants/domains";

import Partners from "components/partners";

import { DEFAULT_CRYPTOCURRENCIES } from "../../duck/constants";

import mainClasses from "../../styles/classes.module.scss";
import classes from "./styles/classes.module.scss";

const TrustedPartners = () => (
  <section className={joinClassNames(mainClasses.section, classes.section)}>
    <div className={classes.contentWrapper}>
      <div className={classes.descriptionWrapper}>
        <h2
          className={joinClassNames(globalClasses.gradientTitle, classes.title)}
        >
          Our Trusted Partners
        </h2>
        <p className={classes.description}>
          We bring the best-in-class partnerships to you all in a single
          application, so you can have key payment insights tailored to your
          purchasing journey with us
        </p>
        <Button
          as="a"
          themeName="primary"
          className={classes.buyCryptoLink}
          target="_blank"
          href={`${APP_URL}/buy/${DEFAULT_CRYPTOCURRENCIES.Bitcoin.letterId}`}
        >
          Buy crypto
        </Button>
      </div>
      <Partners />
    </div>
  </section>
);

export default TrustedPartners;
