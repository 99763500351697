import Button from "basics/button";

import useWindowSizes from "hooks/use-window-sizes";

import classes from "./styles/classes.module.scss";

interface Props {
  currency: Currency;
  cryptocurrency: Cryptocurrency;
}

const SubmitButton = ({ currency, cryptocurrency }: Props) => {
  const { isMobileSmallWidth, isMobileMediumWidth } = useWindowSizes();

  return (
    <Button
      size={isMobileSmallWidth || isMobileMediumWidth ? "lg" : "xl"}
      className={classes.button}
      type="submit"
      disabled={!currency || !cryptocurrency}
      themeName="primary"
    >
      Buy
    </Button>
  );
};

export default SubmitButton;
