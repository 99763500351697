import MobileWalletsIcon from "icons/mobile-wallets.svg?react";
import BankTransfer48Icon from "icons/payment-methods/1.ACH bank transfer.svg?react";
import CreditCard48Icon from "icons/payment-methods/10.Credit card.svg?react";

import { PaymentMethodInfo } from "./types";

export const PAYMENT_METHODS_INFO: PaymentMethodInfo[] = [
  {
    name: "United States of America",
    image: "US.png",
    currency: "USD",
    paymentMethods: [
      {
        icon: MobileWalletsIcon,
        title: "Mobile Wallets",
      },
      {
        icon: BankTransfer48Icon,
        title: "Bank transfer",
      },
    ],
  },
  {
    name: "Australia",
    image: "AU.png",
    currency: "AUD",
    paymentMethods: [
      {
        icon: MobileWalletsIcon,
        title: "Mobile Wallets",
      },
    ],
  },
  {
    name: "United Arab Emirates",
    image: "AE.png",
    currency: "AED",
    paymentMethods: [
      {
        icon: MobileWalletsIcon,
        title: "Mobile Wallets",
      },
      {
        icon: BankTransfer48Icon,
        title: "Bank transfer",
      },
    ],
  },
  {
    name: "Argentina",
    image: "AR.png",
    currency: "ARS",
    paymentMethods: [
      {
        icon: CreditCard48Icon,
        title: "Credit/Debit card",
      },
    ],
  },
  {
    name: "Poland",
    image: "PL.png",
    currency: "PLN",
    paymentMethods: [
      {
        icon: CreditCard48Icon,
        title: "Credit/Debit card",
      },
      {
        icon: BankTransfer48Icon,
        title: "Bank transfer",
      },
    ],
  },
  {
    name: "Canada",
    image: "CA.png",
    currency: "CAD",
    paymentMethods: [
      {
        icon: MobileWalletsIcon,
        title: "Mobile Wallets",
      },
      {
        icon: BankTransfer48Icon,
        title: "Bank transfer",
      },
    ],
  },
  {
    name: "United Kingdom",
    image: "GB.png",
    currency: "GPB",
    paymentMethods: [
      {
        icon: BankTransfer48Icon,
        title: "Bank transfer",
      },
    ],
  },

  {
    name: "Singapore",
    image: "SG.png",
    currency: "SGD",
    paymentMethods: [
      {
        icon: CreditCard48Icon,
        title: "Credit/Debit card",
      },
    ],
  },
];
