export const enum DefaultCoinNames {
  Bitcoin = "Bitcoin",
  Ethereum = "Ethereum",
}

declare interface DefaultCoin {
  id: Coin["id"];
  name: DefaultCoinNames;
  networkId: Network["id"];
  code: Coin["name"];
}

export const Bitcoin: DefaultCoin = {
  id: 1,
  name: DefaultCoinNames.Bitcoin,
  // Prod: 7, Dev: 8
  networkId: 8,
  code: "BTC",
};

export const Ethereum: DefaultCoin = {
  id: 2,
  name: DefaultCoinNames.Ethereum,
  // Prod: 5, Dev: 1
  networkId: 1,
  code: "ETH",
};

export const DEFAULT_COIN_LIST = [Bitcoin, Ethereum];
