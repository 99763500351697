import { FC } from "react";
import joinClassNames from "classnames";

import StarredCheckIcon from "icons/starred-check.svg?react";

import classes from "./styles/classes.module.scss";

import { FeeCardInfo } from "../../duck/types";

const FeeCard: FC<FeeCardInfo> = ({
  themeName,
  providerIcon: ProviderIcon,
  isChecked,
  fee,
  rate,
}) => (
  <div
    className={joinClassNames(classes.themePresets, classes.wrapper)}
    data-theme={themeName}
  >
    <ProviderIcon className={classes.icon} />
    <div className={classes.infoWrapper}>
      <p>
        <span className={classes.label}>You get:</span> {rate}
      </p>
      <div className={classes.feeWrapper}>
        <p>
          <span className={classes.label}>True fee:</span> {fee}%
        </p>
        {isChecked && <StarredCheckIcon className={classes.starredCheckIcon} />}
      </div>
    </div>
  </div>
);

export default FeeCard;
