import { FC, ReactNode, useMemo } from "react";
import joinClassNames from "classnames";

import { getPriceData } from "./duck/selectors";
import { BaseProps } from "../duck/types";

import classes from "../styles/classes.module.scss";

interface Props extends BaseProps {
  isEquivalent?: boolean;
  tokenCode?: string | ReactNode; // ReactNode use only <span> with styles
  isCalculate?: boolean;
  classNames?: Partial<{
    zeros: string;
  }>;
}

const Price: FC<Props> = ({
  value,
  precision,
  isEquivalent = false,
  tokenCode,
  symbol,
  exchangeRate,
  isCalculate = true,
  className,
  classNames = {},
}) => {
  const valueForFormat =
    isCalculate && isEquivalent && !tokenCode
      ? value.times(exchangeRate)
      : value;

  const { postfix, prefixLess, prefixSymbol, pricePart, zerosPart } = useMemo(
    () =>
      getPriceData({
        symbol,
        tokenCode,
        precision,
        value: valueForFormat,
        isEquivalent,
      }),
    [symbol, tokenCode, precision, valueForFormat],
  );

  return (
    <span className={joinClassNames(classes.wrapper, className)}>
      {prefixLess}
      {isEquivalent && <span>{prefixSymbol}</span>}
      {pricePart}
      <span className={classNames.zeros}>{zerosPart}</span>
      {postfix && <> {postfix}</>}
    </span>
  );
};

export default Price;
