import { FC } from "react";

import AppStoreIcon from "icons/app-store.svg?react";
import GooglePlayIcon from "icons/google-play.svg?react";
import QRCodeIcon from "icons/qr-code.svg?react";

import {
  ANDROID_APPLICATION_URL,
  IOS_APPLICATION_URL,
} from "constants/externalLinks";

import classes from "./styles/classes.module.scss";

interface Props {
  isQR?: boolean;
}

const MobileLinks: FC<Props> = ({ isQR = false }) => (
  <div className={classes.wrapper} data-qr={isQR}>
    <QRCodeIcon className={classes.qrCode} />
    <div className={classes.links}>
      <a
        className={classes.link}
        target="_blank"
        href={ANDROID_APPLICATION_URL}
        title="Get it on Google Play"
      >
        <GooglePlayIcon className={classes.icon} />
      </a>
      <a
        className={classes.link}
        target="_blank"
        href={IOS_APPLICATION_URL}
        title="Download on the App Store"
      >
        <AppStoreIcon />
      </a>
    </div>
  </div>
);

export default MobileLinks;
