import React, { FC, Suspense } from "react";
import { Await, useLoaderData } from "@remix-run/react";

import { Price as NativePrice } from "components/numbers";

import { DefaultCoinNames } from "constants/coins";

import { Loader } from "../../../../../../duck/types";

import classes from "./styles/classes.module.scss";

interface Props {
  coinName: DefaultCoinNames;
}

const Price: FC<Props> = ({ coinName }) => {
  const { insights } = useLoaderData<Loader>();

  return (
    <div>
      <p className={classes.priceLabel}>Price</p>
      <Suspense fallback={<div className={classes.priceSkeleton} />}>
        <Await resolve={insights}>
          {insights => (
            <NativePrice
              className={classes.price}
              isEquivalent
              value={insights[coinName].cryptocurrency.price}
              symbol="$"
            />
          )}
        </Await>
      </Suspense>
    </div>
  );
};

export default Price;
