import { FC, Suspense } from "react";
import { Await, useLoaderData } from "@remix-run/react";

import { DefaultCoinNames } from "constants/coins";

import { Loader } from "routes/_index/duck/types";

import ChartLoader from "./components/chart-loader";
import LineChart from "./components/line-chart";

import classes from "./styles/classes.module.scss";

interface Props {
  coinName: DefaultCoinNames;
}

const Chart: FC<Props> = ({ coinName }) => {
  const { quotes } = useLoaderData<Loader>();

  return (
    <div className={classes.wrapper}>
      <Suspense fallback={<ChartLoader />}>
        <Await resolve={quotes}>
          {quotes => <LineChart data={quotes[coinName]} />}
        </Await>
      </Suspense>
    </div>
  );
};

export default Chart;
