import { FC, HTMLAttributes } from "react";
import joinClassNames from "classnames";
import FocusTrap from "focus-trap-react";

import { FOCUS_TRAP_OPTIONS } from "./duck/constants";

import classes from "./styles/classes.module.scss";

const Overlay: FC<HTMLAttributes<HTMLDivElement>> = ({
  children,
  className,
  ...props
}) => (
  <FocusTrap focusTrapOptions={FOCUS_TRAP_OPTIONS}>
    <div
      tabIndex={-1}
      className={joinClassNames(classes.wrapper, className)}
      {...props}
    >
      {children}
    </div>
  </FocusTrap>
);

export default Overlay;
