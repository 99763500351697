import { FC } from "react";

import joinClassNames from "classnames";

import classes from "./styles/classes.module.scss";

interface Props {
  className?: string;
  classNames?: Partial<{ graph: string }>;
}

const ChartLoader: FC<Props> = ({ className, classNames = {} }) => (
  <div className={joinClassNames(classes.graphWrapper, className)}>
    {[classes.graph1, classes.graph2, classes.graph3, classes.graph4].map(
      graphClassName => (
        <div key={graphClassName} className={joinClassNames(graphClassName, classNames.graph)} />
      ),
    )}
  </div>
);

export default ChartLoader;
