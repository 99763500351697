import { FC, Suspense } from "react";
import { Await, useLoaderData } from "@remix-run/react";

import RoundedArrow56Icon from "icons/rounded-arrow56.svg?react";

import { Percent } from "components/numbers";

import { DefaultCoinNames } from "constants/coins";

import { Loader } from "../../../../../../duck/types";

import classes from "./styles/classes.module.scss";

interface Props {
  coinName: DefaultCoinNames;
}

const InsightInfo: FC<Props> = ({ coinName }) => {
  const { insights } = useLoaderData<Loader>();

  return (
    <Suspense
      fallback={
        <>
          <div className={classes.feeSkeleton} />
          <div className={classes.providerSkeleton} />
        </>
      }
    >
      <Await resolve={insights}>
        {insights => {
          const { service, region, currency, paymentMethod, trueFee } =
            insights[coinName];

          const regionInfo = region.parentRegion
            ? `${region.parentRegion.alpha2Code}, ${region.alpha2Code}`
            : region.alpha2Code;

          return (
            <>
              <p className={classes.feeWrapper}>
                <Percent className={classes.feeValue} value={trueFee} />
                <span className={classes.feeLabel}>true fee</span>
              </p>
              <div className={classes.footer}>
                <p className={classes.paymentInfo}>
                  {regionInfo}&nbsp;&bull;&nbsp;
                  {paymentMethod.name} &bull; {currency.name} &bull; via{" "}
                  {service.name}
                </p>
                <RoundedArrow56Icon data-rounded-arrow="true" />
              </div>
            </>
          );
        }}
      </Await>
    </Suspense>
  );
};

export default InsightInfo;
