import joinClassNames from "classnames";

import globalClasses from "styles/classes.module.scss";

import Button from "basics/button";

import { DEFAULT_COIN_LIST } from "constants/coins";
import { APP_URL } from "constants/domains";

import InsightCard from "./components/insight-card";

import mainClasses from "../../styles/classes.module.scss";
import classes from "./styles/classes.module.scss";

const Insights = () => (
  <section className={joinClassNames(mainClasses.section, classes.section)}>
    <div className={classes.contentWrapper}>
      <div className={classes.insightsInfo}>
        <p className={classes.subTitle}>Introducing</p>
        <h2
          className={joinClassNames(globalClasses.gradientTitle, classes.title)}
        >
          Insights
        </h2>
        <p className={classes.description}>
          Say goodbye to decision fatigue. With a simple click, gain access to
          personalized recommendations tailored to your unique preferences.
          Simplify your experience and make informed decisions with ease
        </p>
        <Button
          as="a"
          themeName="primary"
          className={classes.insightsLink}
          target="_blank"
          href={`${APP_URL}/insights`}
        >
          Get your personal insights
        </Button>
      </div>
      <div className={classes.insightsWrapper}>
        {DEFAULT_COIN_LIST.map(({ name, code }) => (
          <InsightCard key={name} coinName={name} code={code} />
        ))}
      </div>
    </div>
  </section>
);

export default Insights;
