import { useMemo } from "react";
import joinClassNames from "classnames";

import globalClasses from "styles/classes.module.scss";

import PaymentMethod from "./components/payment-method";

import { PAYMENT_METHODS_INFO } from "./duck/constants";

import classes from "./styles/classes.module.scss";

const PaymentMethods = () => {
  const paymentMethods = useMemo(
    () =>
      PAYMENT_METHODS_INFO.concat(PAYMENT_METHODS_INFO).map((info, index) => (
        <PaymentMethod key={`${index + 1}`} {...info} />
      )),
    [],
  );

  return (
    <section className={classes.section}>
      <div className={classes.contentWrapper}>
        <p className={classes.description}>
          Select your preferred payment&nbsp;methods&nbsp;and
        </p>
        <h2 className={joinClassNames(globalClasses.gradientTitle, classes.title)}>
          Personalize Your Experience
        </h2>
      </div>
      <div className={classes.carouselWrapper}>
        <div className={classes.carouselTrack}>{paymentMethods}</div>
      </div>
    </section>
  );
};

export default PaymentMethods;
