import CircledIcon, { CoinIcon } from "components/icon";

import { RenderOption } from "../../duck/types";
import { Value } from "./duck/types";

import classes from "./styles/classes.module.scss";

const Option: RenderOption<Value, { iconType?: "circle" | "coin" }> = ({
  value,
  iconType = "circle",
}) => {
  const IconComponent = iconType === "circle" ? CircledIcon : CoinIcon;

  return (
    <div className={classes.wrapper}>
      {value ? (
        <>
          <IconComponent
            className={classes.icon}
            src={value.icon || value.logo}
          />
          <p className={classes.text}>{value.name}</p>
        </>
      ) : (
        <p className={classes.text}>Choose option</p>
      )}
    </div>
  );
};

export default Option;
