import { ImportedSvgIcons } from "types/icons";

import { getSortedSvgs } from "selectors/icons";

export const DISPLAYABLE_PAYMENT_METHODS_COUNT = 7;

const rawPaymentMethodIcons = import.meta.glob("icons/payment-methods/*", {
  eager: true,
  import: "default",
  query: "?react",
}) as ImportedSvgIcons;

export const PAYMENT_METHOD_ICONS = getSortedSvgs(rawPaymentMethodIcons);
