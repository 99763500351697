import { APP_URL } from "constants/domains";

export const BUY_CARDS_INFO = [
  {
    type: "Layer 1",
    name: "Bitcoin",
    url: `${APP_URL}/buy/btc`,
  },
  {
    type: "Layer 1",
    name: "Ethereum",
    url: `${APP_URL}/buy/eth`,
  },
  {
    type: "Stablecoin",
    name: "USDC",
    url: `${APP_URL}/buy/usdc`,
  },
  {
    type: "Trending",
    name: "Solana",
    url: `${APP_URL}/buy/sol`,
  },
];

export const BUY_LINKS = [
  {
    name: "Tether",
    code: "USDT",
    url: `${APP_URL}/buy/usdt`,
  },
  {
    name: "Binance Coin",
    code: "BNB",
    url: `${APP_URL}/buy/bnb`,
  },
  {
    name: "Stellar Lumen",
    code: "XLM",
    url: `${APP_URL}/buy/xlm`,
  },
  {
    name: "Ripple",
    code: "XRP",
    url: `${APP_URL}/buy/xrp`,
  },
  {
    name: "Litecoin",
    code: "LTC",
    url: `${APP_URL}/buy/ltc`,
  },
  {
    name: "Dogecoin",
    code: "DOGE",
    url: `${APP_URL}/buy/doge`,
  },
  {
    name: "Cardano",
    code: "ADA",
    url: `${APP_URL}/buy/ada`,
  },
  {
    name: "Shiba Inu",
    code: "SHIB",
    url: `${APP_URL}/buy/shib`,
  },
  {
    name: "TRON",
    code: "TRX",
    url: `${APP_URL}/buy/trx`,
  },
  {
    name: "Polkadot",
    code: "DOT",
    url: `${APP_URL}/buy/dot`,
  },
  {
    name: "Polygon",
    code: "MATIC",
    url: `${APP_URL}/buy/matic`,
  },
  {
    name: "Avalanche",
    code: "AVAX",
    url: `${APP_URL}/buy/avax`,
  },
  {
    name: "Uniswap",
    code: "UNI",
    url: `${APP_URL}/buy/uni`,
  },
  {
    name: "Toncoin",
    code: "TON",
    url: `${APP_URL}/buy/ton`,
  },
  {
    name: "Dai",
    code: "DAI",
    url: `${APP_URL}/buy/dai`,
  },
  {
    name: "Cronos",
    code: "CRO",
    url: `${APP_URL}/buy/cro`,
  },
  {
    name: "Chainlink",
    code: "LINK",
    url: `${APP_URL}/buy/link`,
  },
  {
    name: "Bitcoin Cash",
    code: "BCH",
    url: `${APP_URL}/buy/bch`,
  },
];
