import { FC } from "react";
import {
  ResponsiveContainer,
  LineChart as NativeLineChart,
  Line,
  YAxis,
} from "recharts";

import ChartLoader from "../chart-loader";

import classes from "./styles/classes.module.scss";

interface Props {
  data: Quote[];
}

const LineChart: FC<Props> = ({ data }) =>
  data.length ? (
    <ResponsiveContainer>
      <NativeLineChart
        style={{ cursor: "pointer" }}
        margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
        data={data}
      >
        <YAxis domain={["auto", "auto"]} hide />
        <Line
          dataKey="value"
          type="monotone"
          stroke="#dfdef0"
          strokeWidth={2}
          dot={false}
        />
      </NativeLineChart>
    </ResponsiveContainer>
  ) : (
    <div className={classes.noDataWrapper}>
      <ChartLoader
        className={classes.noDataLoader}
        classNames={{ graph: classes.noDataLoaderGraph }}
      />
      <p className={classes.noDataText}>No chart data</p>
    </div>
  );

export default LineChart;
