import { Modal } from "components/dialog";
import Scrollbar from "components/scrollbar";

import useWindowSizes from "hooks/use-window-sizes";

import { SCROLLBAR_PROPS } from "./duck/constants";
import { PAYMENT_METHOD_ICONS } from "../../duck/constants";

import classes from "./styles/classes.module.scss";

const PaymentMethodsModal = () => {
  const { isMobileWidth } = useWindowSizes();

  return (
    <Modal>
      <Modal.Header>Available payment methods</Modal.Header>
      <Scrollbar
        rightOffset={-20}
        style={{ maxHeight: isMobileWidth ? null : "344px" }}
        contentProps={SCROLLBAR_PROPS.contentProps}
        className={classes.scrollbar}
        translateContentSizeYToHolder
      >
        <ul className={classes.paymentMethods}>
          {PAYMENT_METHOD_ICONS.map(Icon => (
            <li key={Icon.title} className={classes.paymentMethod}>
              <Icon className={classes.icon} />
              <p className={classes.name}>{Icon.title}</p>
            </li>
          ))}
        </ul>
      </Scrollbar>
    </Modal>
  );
};

export default PaymentMethodsModal;
