import {
  forwardRef,
  useRef,
  useImperativeHandle,
  ButtonHTMLAttributes,
} from "react";
import { Combobox } from "@headlessui/react";

import styles from "./styles/trigger.module.scss";

const Trigger = forwardRef<
  HTMLButtonElement,
  ButtonHTMLAttributes<HTMLButtonElement> & { isReadOnly?: boolean }
>(({ children, isReadOnly, ...restProps }, ref) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const triggerRef = useRef<HTMLButtonElement>(null);

  useImperativeHandle(ref, () => buttonRef.current);

  return (
    <button
      {...restProps}
      type="button"
      data-readonly={isReadOnly}
      onClick={() => {
        if (!isReadOnly) {
          triggerRef.current.click();
        }
      }}
      ref={buttonRef}
    >
      <Combobox.Button
        as="div"
        ref={triggerRef}
        className={styles.nativeButton}
      />
      {children}
    </button>
  );
});

export default Trigger;
