import {
  Scrollbar as NativeScrollbar,
  ScrollbarProps,
} from "react-scrollbars-custom";
import merge from "lodash/merge";
import pick from "lodash/pick";
import omit from "lodash/omit";
import joinClassNames from "classnames";

import { getCSSVariables } from "selectors/common";

import styles from "./styles/scrollbar.module.scss";

interface Props extends Omit<ScrollbarProps, "ref"> {
  trackXOffset?: number;
  trackYOffset?: number;
  rightOffset?: number;
  thumbWidth?: number;
  className?: string;
  classNames?: Partial<{
    yTrackWrapper: string;
    yTrack: string;
    thumb: string;
  }>;
}

const Scrollbar = ({
  children,
  trackYProps,
  thumbYProps,
  trackXOffset = 4,
  rightOffset = 0,
  trackYOffset = 16,
  thumbWidth = 4,
  wrapperProps,
  style,
  classNames = {},
  ...restProps
}: Props) => (
  <NativeScrollbar
    {...restProps}
    style={merge(
      getCSSVariables({
        rightOffset: `${rightOffset}px`,
        thumbWidth: `${thumbWidth}px`,
        trackXOffset: `${trackXOffset}px`,
        trackYOffset: `${trackYOffset}px`,
      }),
      style,
    )}
    wrapperProps={merge({ style: { right: 0 } }, wrapperProps)}
    trackYProps={merge(
      {
        renderer: ({ children, elementRef, className, ...props }) => (
          <div
            className={joinClassNames(
              styles.yTrackWrapper,
              classNames.yTrackWrapper,
            )}
          >
            <div
              className={joinClassNames(
                styles.yTrack,
                classNames.yTrack,
                className,
              )}
              ref={elementRef}
              {...omit(props, "style")}
            >
              {children}
            </div>
          </div>
        ),
      },
      trackYProps,
    )}
    thumbYProps={merge(
      {
        renderer: ({ children, elementRef, style, className, ...props }) => (
          <div
            className={joinClassNames(
              styles.thumb,
              classNames.thumb,
              className,
            )}
            ref={elementRef}
            style={pick(style, ["transform", "height"])}
            {...props}
          >
            {children}
          </div>
        ),
      },
      thumbYProps,
    )}
  >
    {children}
  </NativeScrollbar>
);

export default Scrollbar;
