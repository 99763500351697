import { Bitcoin, DefaultCoinNames, Ethereum } from "constants/coins";

export const DEFAULT_CURRENCY = {
  id: 3,
  name: "USD",
  symbol: "$",
  icon: "https://api-staging.coindisco.com/media/currencies/icons/USD.png",
  isPopular: true,
  default: true,
};

export const FALLBACK_INSIGHTS_INFO = {
  // Prod: 173, Dev: 132
  regionId: 132,
  currencyId: DEFAULT_CURRENCY.id,
};

// TODO: add revolut
export const DEFAULT_PAYMENT_METHODS: PaymentOption[] = [
  {
    id: 1,
    code: "ach_bank_transfer",
    name: "ACH Payment Method",
    icon: "https://api.coindisco.com/media/service_integration/PaymentMethod/ach_bank_transfer.png",
    category: "Bank transfer",
    categoryIcon:
      "https://api.coindisco.com/static/service_integration/categories_pm/bank_transfer.png",
    categoryDescription: "Lowest fee",
    isDefault: true,
    regionId: FALLBACK_INSIGHTS_INFO.regionId,
  },
  {
    id: 6,
    code: "sepa_bank_transfer",
    name: "SEPA bank transfer",
    icon: "https://api.coindisco.com/media/service_integration/PaymentMethod/sepa_bank_transfer.png",
    category: "Bank transfer",
    categoryIcon:
      "https://api.coindisco.com/static/service_integration/categories_pm/bank_transfer.png",
    categoryDescription: "Lowest fee",
    isDefault: true,
    regionId: FALLBACK_INSIGHTS_INFO.regionId,
  },
  {
    id: 43,
    code: "CLEARJUNCTIONFP",
    name: "Faster Payments",
    icon: "https://api.coindisco.com/media/service_integration/PaymentMethod/CLEARJUNCTIONFP.png",
    category: "Bank transfer",
    categoryIcon:
      "https://api.coindisco.com/static/service_integration/categories_pm/bank_transfer.png",
    categoryDescription: "Lowest fee",
    isDefault: true,
    regionId: FALLBACK_INSIGHTS_INFO.regionId,
  },
  {
    id: 9,
    code: "mobile_wallet",
    name: "Google Pay",
    icon: "https://api.coindisco.com/media/service_integration/PaymentMethod/mobile_wallet_android.png",
    category: "Mobile Wallets",
    categoryIcon:
      "https://api.coindisco.com/static/service_integration/categories_pm/wallets_pay.png",
    categoryDescription: "Instant payment",
    isDefault: true,
    regionId: FALLBACK_INSIGHTS_INFO.regionId,
  },
  {
    id: 5,
    code: "mobile_wallet",
    name: "Apple Pay",
    icon: "https://api.coindisco.com/media/service_integration/PaymentMethod/mobile_wallet_ios.png",
    category: "Mobile Wallets",
    categoryIcon:
      "https://api.coindisco.com/static/service_integration/categories_pm/wallets_pay.png",
    categoryDescription: "Instant payment",
    isDefault: true,
    regionId: FALLBACK_INSIGHTS_INFO.regionId,
  },
];

export const DEFAULT_CRYPTOCURRENCIES: Record<
  DefaultCoinNames,
  Cryptocurrency
> = {
  [DefaultCoinNames.Bitcoin]: {
    id: 1,
    name: "Bitcoin",
    symbol: "btc",
    letterId: "btc",
    rank: 1,
    logo: "https://api-staging.coindisco.com/media/currencies/logo/btc.jpg",
    buyAvailable: true,
    isFavorite: true,
    networks: [
      {
        id: Bitcoin.networkId,
        name: "Bitcoin",
        code: "bitcoin",
        icon: "https://api-staging.coindisco.com/media/service_integration/Network/bitcoin.png",
        regex: "^[13][a-km-zA-HJ-NP-Z1-9]{25,34}$|^(bc1)[0-9A-Za-z]{39,59}$",
      },
    ],
  },
  [DefaultCoinNames.Ethereum]: {
    id: 2,
    name: "Ethereum",
    symbol: "eth",
    letterId: "eth",
    rank: 2,
    logo: "https://api-staging.coindisco.com/media/currencies/logo/eth.jpg",
    buyAvailable: true,
    isFavorite: false,
    networks: [
      {
        id: Ethereum.networkId,
        name: "Ethereum",
        code: "ethereum",
        icon: "https://api-staging.coindisco.com/media/service_integration/Network/ethereum.png",
        regex: "^(0x)[0-9A-Fa-f]{40}$",
      },
    ],
  },
};
