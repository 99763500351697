import StripeLogoIcon from "icons/stripe-uncolored.svg?react";
import MoonpayLogoIcon from "icons/moonpay-uncolored.svg?react";
import RampLogoIcon from "icons/ramp-uncolored.svg?react";

import { FeeCardInfo } from "./types";

export const FEE_CARDS_INFO: FeeCardInfo[] = [
  {
    themeName: "Stripe",
    providerIcon: StripeLogoIcon,
    rate: "1.15 ETH",
    fee: 2,
    isChecked: true,
  },
  {
    themeName: "Moonpay",
    providerIcon: MoonpayLogoIcon,
    rate: "0.023 BTC",
    fee: 2.02,
    isChecked: false,
  },
  {
    themeName: "Ramp",
    providerIcon: RampLogoIcon,
    rate: "35 USDC",
    fee: 2.3,
    isChecked: false,
  },
];
