import joinClassNames from "classnames";

import globalClasses from "styles/classes.module.scss";

// import LearnLink from "components/learn-link/LearnLink";

import FeeCard from "./components/fee-card";
import { FEE_CARDS_INFO } from "./duck/constants";

import mainClasses from "../../styles/classes.module.scss";
import classes from "./styles/classes.module.scss";

const DiscoverTrueFee = () => (
  <section className={joinClassNames(mainClasses.section, classes.section)}>
    <div className={classes.contentWrapper}>
      <div className={classes.descriptionWrapper}>
        <h2
          className={joinClassNames(globalClasses.gradientTitle, classes.title)}
        >
          Discover True Fee
        </h2>
        <p className={classes.description}>
          We believe in transparency down to the last cent. Our engine reveals
          all forms of hidden fees, so you know exactly how much you are paying
          for
        </p>
        {/*<LearnLink>Learn more about true fee</LearnLink>*/}
      </div>
      <div className={classes.feesWrapper}>
        {FEE_CARDS_INFO.map(info => (
          <FeeCard key={info.themeName} {...info} />
        ))}
      </div>
    </div>
  </section>
);

export default DiscoverTrueFee;
