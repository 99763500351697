import { ComponentProps, FC, Fragment } from "react";
import { Combobox } from "@headlessui/react";

import Tick24Icon from "icons/tick24.svg?react";

import { LabelValue, RenderOption } from "../../duck/types";

import styles from "./styles/option.module.scss";

interface Props {
  data: LabelValue<unknown>;
  renderOption: RenderOption;
  component: FC<ComponentProps<typeof Combobox.Option>>;
}

const Option: FC<Props> = ({
  component: Wrapper,
  data: { label, value, disabled },
  renderOption: OptionComp,
}) => (
  <Wrapper value={value} key={label} disabled={Boolean(disabled)} as={Fragment}>
    {({ selected, disabled, active }) => (
      <li className={styles.option} aria-disabled={disabled}>
        <OptionComp
          active={active}
          disabled={disabled}
          selected={selected}
          value={value}
        />
        {selected && <Tick24Icon className={styles.tickIcon} />}
      </li>
    )}
  </Wrapper>
);

export default Option;
